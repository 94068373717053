<!-- eslint-disable vue/valid-v-else-if -->
<template>
	<div class=".gg-container-1">
	  <div class="search-container">
		<!--输入框-->
		<div class="search-container-fn-input">
		  <label>医生姓名：</label>
		  <el-input
			style="width:200px"
			size="mini"
			placeholder="请输入医生姓名"
			prefix-icon="el-icon-search"
			v-model="searchParams.doctor_name"
			clearable
			@change="getList('restPage')"
		  >
		  </el-input>
		</div>
			<el-button
				size="mini"
				type="primary"
				style="padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
				@click="getList('restPage')"
			>
				<span>查找</span>
			</el-button>
		<div
		  style="margin-left: auto; display: flex; justify-content: space-around"
		>
		<div class="search-container-fn-input" v-if="operateList.indexOf('update_supervise_status') > -1">
			<el-button
			  size="mini"
			  type="primary"
			  icon="el-icon-edit-outline"
			  style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
								  background-color: #2362FB; border-color: #2362FB"
			  @click="updateSuperviseStatus(multipleSelectionPres)"
			  v-if="multipleSelectionPres.length >= 1"
			  >更新备案状态
			</el-button>
		  </div>
		  <div class="search-container-fn-input" v-if="operateList.indexOf('upload') > -1">
			<el-button
			  size="mini"
			  type="primary"
			  icon="el-icon-upload2"
			  style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
								  background-color: #2362FB; border-color: #2362FB"
			  @click="uploadSupervision(multipleSelectionPres[0].doctors_id)"
			  v-if="multipleSelectionPres.length == 1"
			  >上传监管
			</el-button>
		  </div>
		  <div class="search-container-fn-input" v-if="operateList.indexOf('update_table') > -1">
			<el-button
			  size="mini"
			  type="primary"
			  icon="el-icon-edit-outline"
			  style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
								  background-color: #2362FB; border-color: #2362FB"
			  @click="handleEditTableHead"
			  >编辑表头
			</el-button>
		  </div>
		  <div class="search-container-fn-input" v-if="operateList.indexOf('reset_query') > -1">
			<el-button
			  size="mini"
			  type="primary"
			  icon="el-icon-refresh"
			  style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
								  background-color: #2362FB; border-color: #2362FB"
			  @click="handleResetSearch"
			  >重置查找
			</el-button>
		  </div>
		  <!--对应第三方图标的button样式-->
		</div>
	  </div>
	  <!----------------表格---------------->
	  <el-table
		border
		size="mini"
		v-loading="listLoading"
		:header-cell-style="{ 'text-align': 'center' }"
		:data="tableData"
		height="725"
		@selection-change="handleSelectionChange"
		style="width: 100%;z-index:0"
	  >
		<el-table-column type="selection" width="55" align="center" />
		<el-table-column type="index" align="center" width="50">
		</el-table-column>
		<template v-for="(column, index) in tableHead">
		  <el-table-column
			:prop="column.column_prop"
			:label="
			  column.column_label_user_definition
				? column.column_label_user_definition
				: column.column_label
			"
			:width="column.width ? column.width : ''"
			:key="index"
			align="center"
			v-if="column.field_type === 'textBtn' && column.visible === true"
			show-overflow-tooltip
		  >
			<template slot-scope="scope">
			  <a
				style="font-size: 12px; color: #2379fb"
				@click.prevent="
				  currUser = scope.row;
				  getInfo(scope.row.doctors_id);
				"
			  >
				{{ scope.row[column.column_prop] }}
			  </a>
			</template>
		  </el-table-column>
		  <!-- <el-table-column
			:prop="column.column_prop"
			:label="
			  column.column_label_user_definition
				? column.column_label_user_definition
				: column.column_label
			"
			:width="column.width ? column.width : ''"
			:key="index"
			align="center"
			v-if="column.field_type === 'image' && column.visible === true"
			show-overflow-tooltip
		  >
			<template slot-scope="scope">
			  <el-avatar :src="scope.row.head_pic"></el-avatar>
			</template>
		  </el-table-column> -->
		  <el-table-column
			:prop="column.column_prop"
			:label="
			  column.column_label_user_definition
				? column.column_label_user_definition
				: column.column_label
			"
			:width="column.width ? column.width : ''"
			:key="index"
			align="center"
			v-else-if="column.field_type === 'select' && column.visible === true"
			show-overflow-tooltip
		  >
			<template slot-scope="scope">
			  <div v-if="column.column_label == '名医认证'">
				<el-switch
				  v-model="scope.row[column.column_prop]"
				  active-color="#13ce66"
				  inactive-color="#ff4949"
				  :active-value="2"
				  :inactive-value="1"
				  @change="institutionalStatus(scope.row)"
				>
				</el-switch>
			  </div>
			  <div
				v-else-if="
				  $store.state.user.is_admin == 1 &&
					column.column_label == '医师机构'
				"
			  >
				<el-select
				  v-model="scope.row.agent_name"
				  placeholder="请选择"
				  @change="agentChange($event, scope.row)"
				>
				  <el-option
					v-for="item in optionsName"
					:key="item.agent_id"
					:label="item.agent_name"
					:value="item.agent_id"
				  >
				  </el-option>
				</el-select>
			  </div>
			</template>
		  </el-table-column>
		  <el-table-column
			:sortable="tableHeadSortable.includes(column.column_prop)"
			:prop="column.column_prop"
			:label="
			  column.column_label_user_definition
				? column.column_label_user_definition
				: column.column_label
			"
			:width="column.width ? column.width : ''"
			:key="index"
			align="center"
			v-else-if="column.visible === true"
			show-overflow-tooltip
		  >
			<template #header>
			  <el-popover
				placement="bottom"
				title=""
				min-width="160"
				trigger="click"
				v-if="
				  searchTableHead.filter(
					(item) => item.name == column.column_prop
				  ).length > 0
				"
			  >
				<span slot="reference" class="search-header">
				  <span class="search-title">{{
					column.column_label_user_definition
					  ? column.column_label_user_definition
					  : column.column_label
				  }}</span>
				  <i
					style="margin-left: 3px;font-weight: bolder; font-size: 20px"
					class="el-icon-search"
					:class="{
					  isSearch: searchTableHead.filter(
						(item) => item.name == column.column_prop
					  )[0].isSearch,
					  'el-icon-zoom-in': searchTableHead.filter(
						(item) => item.name == column.column_prop
					  )[0].isSearch,
					}"
				  />
				</span>
				<el-cascader
				  v-if="'depart_name' == column.column_prop"
				  v-model="searchParams.depart_id"
				  style="width: 200px"
				  clearable
				  size="small"
				  :options="departSymptom"
				  @change="reset"
				  :props="{ checkStrictly: true }"
				>
				</el-cascader>
				<el-select
				  v-if="'upload_name' == column.column_prop"
				  style="width: 200px"
				  @change="
					handleSearch(column.column_prop, searchParams.upload_name)
				  "
				  v-model="searchParams.upload_status"
				  clearable
				  placeholder="请选择"
				  size="small"
				>
				  <el-option
					v-for="item in filingStatus"
					:key="item.value"
					:label="item.label"
					:value="item.value"
				  >
				  </el-option>
				</el-select>
				<el-input
				  v-if="'mobile' == column.column_prop"
				  size="mini"
				  placeholder=""
				  prefix-icon="el-icon-search"
				  v-model="searchParams.mobile"
				  clearable
				  @change="handleSearch(column.column_prop, searchParams.mobile)"
				>
				</el-input>
			  </el-popover>
			  <span v-else>{{
				column.column_label_user_definition
				  ? column.column_label_user_definition
				  : column.column_label
			  }}</span>
			</template>
		  </el-table-column>
		</template>
	  </el-table>
  
	  <!----------------分页---------------->
	  <!--<pagination :total="total" :page.sync="listQuery.page" -->
	  <!--						:limit.sync="listQuery.limit"-->
	  <!--						@pagination="getList"/>-->
  
	  <pagination
		:total="total"
		:page.sync="listQuery.page"
		:limit.sync="listQuery.limit"
		@pagination="getList"
	  />
	  <!--编辑表头-->
	  <editTableHead
		:isActiveEditTableHead.sync="isActiveEditTableHead"
		@_getAdminFieldIndex="_getAdminFieldIndex"
		v-if="isActiveEditTableHead"
		@getList="getList"
    :table_type="table_type"
	  >
	  </editTableHead>
	  <el-drawer
		:append-to-body="true"
		:show-close="false"
		size="76%"
		:visible.sync="dialogTableVisible"
		direction="rtl"
	  >
		<physicianDetail
		  doctorType="1"
		  :type="'view'"
		  :info="info"
		  :id="currUser.doctors_id"
		  v-if="dialogTableVisible"
		>
		</physicianDetail>
	  </el-drawer>
	</div>
  </template>
  
  <script>
  import editTableHead from "@/components/editTableHead/editTableHead";
  import { getAdminFieldIndex } from "@/api/drugs2.0";
  import Pagination from "@/components/Pagination"; // secondary package based on el-pagination
  import physicianDetail from "../doctorManage/physicianDetail2";
  import globalData from "@/utils/globalData.js"
  import {
	physicianTermination,
	getDoctorsDetailNew,
	physicianSupervisionList,
	uploadSupervision,
	updateSupervision
  } from "@/api/audit/doctor2.0.js";
  import { Message } from "element-ui";
  import { mapState } from "vuex";
  
  export default {
	name: "doctorSupervise",
	async created() {
	  await this._getAdminFieldIndex();
	  this.getList();
	},
	data() {
	  return {
		filingStatus:globalData.superviseStatus,
        table_type:'doctorSupervise',
		operateList: [],
		isActiveEditTableHead: false,
		multipleSelectionPres: [],
		activities: [],
		tableHead: [],
		searchTableHead: [
		  //控制搜索
		  {
			name: "mobile",
			isSearch: false,
		  },
		  {
			name: "upload_name",
			isSearch: false,
		  },
		  {
			name: "depart_name",
			isSearch: false,
		  },
		],
		tableHeadSortable: [
		  //控制排序
		  "created_at",
		  "expired_at",
		  "next_visit_at",
		  "end_visit_at",
		  "end_order_at",
		],
		type: 1,
		showPop: false,
		currUser: {},
		denialReason: "",
		info: {},
		passReview: "Y",
		selected: false,
		clearFilter: false,
		dialogTitle: "",
		dialogType: "",
		datetimerange: [],
		dialogTableVisible: false,
		searchParams: {
		  orderTime: [],
		},
		total: 0,
		listLoading: false,
		listQuery: {
		  page: 1,
		  limit: 100,
		  importance: undefined,
		  title: undefined,
		  type: undefined,
		  sort: "+id",
		},
		tableData: [],
		options: [],
		ruleForm: {
		  examine: "",
		},
		rules: {
		  examine: [
			{
			  required: true,
			  message: "请选择审核结果",
			  trigger: "change",
			},
		  ],
		},
	  };
	},
	watch: {
	  dialogTableVisible(n, v) {
		if (!n) {
		  this.getList();
		}
	  },
	},
	computed: {
	  ...mapState({
		doctorStatus: (state) => state.dictionary.doctorStatus,
		doctorDic: (state) => state.dictionary.doctorDic, //字典
		departSymptom: (state) => state.dictionary.departDic, //字典
		table_options: (state) => state.user.table_options,
	  }),
	},
	components: {
	  Pagination,
	  physicianDetail,
	  editTableHead,
	},
	mounted() {
	  this.$store.dispatch("dictionary/getDicData", [
		{
		  stateName: "doctorDic",
		  api: "/admin/hospital/getDicts",
		},
	  ]);
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
	  this.$store.dispatch("dictionary/getDicData", [
		{
		  stateName: "departDic",
		  api: "/admin/hospital/getDeparts",
		},
	  ]);
	},
	methods: {
	  handleSelectionChange(val) {
		//   this.multipleSelection = [];
		//   this.multipleSelection = val.map((v, k, a) => v.diag_id);
		console.log(val, "val");
		this.multipleSelectionPres = [];
		val.forEach((item) => {
		  this.multipleSelectionPres.push(item);
		});
	  },
	  handleSearch(prop, value) {
		console.log(prop, value, "2312343");
		this.searchTableHead.forEach((item) => {
		  if (item.name == prop) {
			if (value == "") {
			  item.isSearch = false;
			} else {
			  item.isSearch = true;
			}
		  }
		});
		this.getList("restPage");
	  },
	  handleResetSearch() {
		this.searchParams = {
		  is_wx: "",
		  visit_level: "",
		  buy_count: "",
		};
		this.searchTableHead.forEach((item) => {
		  item.isSearch = false;
		});
		this.choiceDateCreateRecord = [];
		this.choiceDateExpire = [];
		this.choiceDateEndVisit = [];
		this.choiceDateEndOrder = [];
		this.choiceDateNextVisit = [];
		this.getList("restPage");
	  },
	  handleEditTableHead() {
		this.isActiveEditTableHead = true;
	  },
	  async _getAdminFieldIndex() {
		// try {
		//   this.listLoading = true;
		//   var params = {
		// 	type: "doctorSupervise",
		//   };
		//   const res = await getAdminFieldIndex(params);
		  this.tableHead = this.table_options.doctorSupervise;
		//   console.log(this.tableHead);
		// } catch (err) {
		//   //在此处理错误
		// } finally {
		//   this.listLoading = false;
		// }
	  },
	  updateSuperviseStatus(ids){
		let doctorIds = ids.map(item =>  item.doctors_id)
		this.$confirm("将从监管平台更新医师最新备案状态", "提示", {
		  confirmButtonText: "确定",
		  cancelButtonText: "取消",
		  type: "warning",
		})
		  .then(() => {
			this.listLoading = true;
			let params = {
			  ids: doctorIds,
			};
			updateSupervision(params)
			  .then((res) => {
				if (res.code == 200) {
				  console.log("res:", res);
				  this.$message({
					type: "success",
					message: "更新成功！",
				  });
				  this.getList();
				} else {
				  this.$message({
					type: "error",
					message: res.message ? res.message : res.msg,
				  });
				  this.getList();
				  this.listLoading = false;
				}
			  })
			  .catch((err) => {
				this.$message({
				  type: "error",
				  message: err.message ? err.message : err.msg,
				});
				this.listLoading = false;
			  });
		  })
		  .catch(() => {
			this.$message({
			  type: "info",
			  message: "已取消上传",
			});
		  });
	  },
	  //上传监管
	  uploadSupervision(id) {
		this.$confirm("是否上传监管", "提示", {
		  confirmButtonText: "确定",
		  cancelButtonText: "取消",
		  type: "warning",
		})
		  .then(() => {
			this.listLoading = true;
			let params = {
			  id: id,
			};
			uploadSupervision(params)
			  .then((res) => {
				if (res.code == 200) {
				  console.log("res:", res);
				  this.$message({
					type: "success",
					message: "上传成功",
				  });
				  this.getList();
				} else {
				  this.$message({
					type: "error",
					message: res.message ? res.message : res.msg,
				  });
				  this.getList();
				  this.listLoading = false;
				}
			  })
			  .catch((err) => {
				this.$message({
				  type: "error",
				  message: err.message ? err.message : err.msg,
				});
				this.listLoading = false;
			  });
		  })
		  .catch(() => {
			this.$message({
			  type: "info",
			  message: "已取消上传",
			});
		  });
	  },
	  //暂未开放
	  notOpen() {
		this.$message({
		  message: "暂未开放",
		  type: "warning",
		});
	  },
	  //清除高级搜索
	  clearState() {
		this.searchParams.status = "";
		this.searchParams.upload_status = "";
	  },
	  approvalStatus(e) {
		this.searchParams.status = e;
	  },
	  clearText() {
		this.searchParams.doctor_name = "";
		this.searchParams.mobile = "";
		this.searchParams.depart_id = "";
		this.searchParams.professional = "";
		this.getList();
	  },
	  popoShow() {
		this.clearFilter = true;
		this.selected = true;
	  },
	  popoHide() {
		this.selected = false;
		this.clearFilter = false;
	  },
	  getInfo(id) {
		let params = {
		  id: id,
		};
		getDoctorsDetailNew(params)
		  .then((response) => {
			let data = response;
			if (data.code == 200) {
			  let _data = data.data;
			  this.info = {
				..._data,
			  };
			  this.dialogTableVisible = true;
			}
			if (this.info.depart_id) {
			  this.info.depart_id = Number(this.info.depart_id);
			}
			this.listLoading = false;
		  })
		  .catch((error) => {
			Message({
			  message: error.message,
			  type: "error",
			});
			this.listLoading = false;
		  });
	  },
	  getList() {
		this.listLoading = true;
		let params = {
		  type: this.type,
		  ...this.searchParams,
		};
		console.log(params, 1111);
		if (params.depart_id && params.depart_id.length == 2) {
		  params.depart_id.splice(0, 1);
		}
		console.log(params, 2222);
		params.page = this.listQuery.page;
		params.type = 1;
		params.page_size = this.listQuery.limit;
		physicianSupervisionList(params)
		  .then((response) => {
			let data = response;
			if (data.code == 200) {
			  let _data = data.data;
			  this.total = _data.total;
			  this.listQuery.limit = Number(_data.per_page);
			  this.tableData = _data.list;
			}
			this.listLoading = false;
			this.listLoading = false;
		  })
		  .catch((error) => {
			Message({
			  message: error.message,
			  type: "error",
			});
			this.listLoading = false;
			this.listLoading = false;
		  });
	  },
	  reset() {
		// this.searchParams = {};
		this.listQuery.page = 1;
		this.getList();
	  },
  
	  dateChange(val) {
		if (val && val.length) {
		  this.searchParams.created_at = JSON.stringify(val);
		} else {
		  this.searchParams.created_at = [];
		}
		this.getList();
	  },
	  editDoctor() {
		this.$router.push({
		  name: "addDoctor",
		  query: {
			type: "1",
			name: "doctor",
		  },
		});
	  },
	  editSuccess() {
		alert("编辑成功");
		this.dialogTableVisible = false;
		this.getList();
	  },
	},
	filters: {
	  controllshow(str, num) {
		//如果当前字符串小于nunm，返回原值
		if (str) {
		  if (str.length <= num) {
			return str;
		  }
		  if (str.length > num) {
			return str.substr(0, num - 1) + "...";
		  }
		} else {
		  return str;
		}
	  },
	},
  };
  </script>
  
  <style scoped lang="scss">
  .edit-dialog {
	min-height: 20vh;
	max-height: 40vh;
	overflow: auto;
 
	.ipt {
	  width: 80%;
	}
 
	.el-date-editor.el-input,
	.el-date-editor.el-input__inner {
	  width: 80%;
	}
  }
  
  .pass-review {
	width: 15px;
	height: 15px;
	background-color: #2362fb;
	border: 1px solid #dfe4ed;
	margin-left: 8px;
  }
  
  .flex {
	display: flex;
	align-items: center;
  }
  
  .review {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	border: 1px solid #dfe4ed;
	margin-left: 8px;
  }
  
  .popover-width {
	background-color: red !important;
	font-size: 50px;
  }
  
  .title-filter {
	display: flex;
	height: 32px;
	line-height: 32px;
 
	.clearText {
	  color: #3066fb;
	  font-size: 13px;
	}
 
	.clearText:hover {
	  cursor: pointer;
	}
 
	.filter:hover {
	  cursor: pointer;
	}
 
	.advancedScreenings {
	  background-color: white;
	  width: 28px;
	  height: 28px;
	  line-height: 30px;
	  border: 1px solid #c7c7c7;
	  border-radius: 4px;
	  margin-left: 10px;
  
	  img {
		display: inline-block;
		margin-left: 5px;
		margin-top: 5px;
		width: 18px;
		height: 18px;
	  }
	}
 
	.advancedScreening {
	  background-color: rgb(24, 144, 255);
	  width: 28px;
	  height: 28px;
	  line-height: 30px;
	  border-radius: 4px;
	  margin-left: 20px;
  
	  img {
		display: inline-block;
		margin-left: 5px;
		margin-top: 5px;
		width: 18px;
		height: 18px;
	  }
  
	  &:hover {
		cursor: pointer;
	  }
	}
 
	.new-doc {
	  padding: 7px 8px;
	  margin-left: 10px;
	  box-sizing: border-box;
	  height: 28px;
	}
 
	.btnn {
	  height: 32px;
	  padding: 0 10px;
	  margin-left: 20px;
	  line-height: 32px;
	}
  }
  
  .doc-title {
	position: relative;
	height: 30px;
 
	img {
	  width: 25px;
	  height: 25px;
	  position: absolute;
	  top: 2px;
	}
 
	span {
	  display: inline-block;
	  margin-left: 30px;
	  line-height: 30px;
	  font-weight: bold;
	}
  }
  
 ::v-deep .el-drawer {
	bottom: 0 !important;
  }
  
 ::v-deep .el-drawer__header {
	padding: 0;
	margin: 0;
  }
  
 ::v-deep .el-tabs__header {
	margin: 0;
  }
  
 ::v-deep .el-drawer.rtl {
	overflow: auto;
	height: 91vh;
	bottom: 0;
	margin-top: 9vh;
	position: absolute;
  }
  
 ::v-deep .el-drawer__container ::-webkit-scrollbar {
	display: none;
  }
  
  .btn {
	width: 53px;
	padding: 6px 0;
  }
  
  //::v-deep #el-popover-8416{
  // 	background-color: red !important;
  // }
  // .el-popover{
  // 	width: 100% !important;
  // 	min-width: none !important;
  // }
  </style>
